import React, {useContext} from 'react';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import Container from '../components/container';
import PageTitle from '../components/page-title';
import classes from './page-template.module.css';

interface PageProps {
  pageContext: {
    title: string;
    content: string;
  };
}

const Page: React.FC<PageProps> = ({pageContext}) => {
  const {theme} = useContext(GlobalStateContext);
  const {title, content} = pageContext;

  return (
    <Layout>
      <Container>
        <PageTitle title={title} />
      </Container>

      <Container>
        <div
          className={clsx(classes.content, theme !== 'regular' && classes.content_black_and_white)}
          dangerouslySetInnerHTML={{__html: content}}
        />
      </Container>
    </Layout>
  );
};

export default Page;
